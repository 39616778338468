import Vue from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import "@/util/axios.js";
import "@/assets/css/main.css";
import Common from "@/util/common.js";
import Store from "@/util/store.js";
import global from '@/util/global.js';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import locale from 'element-ui/lib/locale/lang/zh-CN';


Vue.prototype.$axios = axios;
Vue.prototype.$pagination = global.pagination;
Vue.prototype.$basicUrl = global.basicUrl;
Vue.prototype.$skipPageUrl = global.skipPageUrl;
Vue.use(Common);
Vue.use(ElementUI, { locale, size: 'small' });
const store = Store;

router.beforeEach((to, from, next) => {
    if (to.meta.requireAuth) {
        if (localStorage.getItem('token')) {
            next();
        } else {
            next({
                path: '/login',
                query: { redirect: to.fullPath }
            });
        }
    } else {
        next();
    }
});
/* eslint-disable no-new */
new Vue({
    el: '#app',
    router,
    store,
    components: { App },
    template: '<App/>'
});