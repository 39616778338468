import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    breadcrumb: [
      {text: '首页', disabled: false, href: '/home'}
    ],
    currMenu:''
  },
  mutations: {
    updateBreadcrumb(state, breadcrumb) {
      state.breadcrumb = [];
      state.breadcrumb = breadcrumb;
    },
    updateMenu(state, data){
      state.currMenu = data;
    },
  }
});
