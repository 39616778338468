
<template>
  <div class="footer">
    <div class="wrapper p-20">
      <div class="center-float-center p-20">
        <router-link class="p-10" to="/about/us"> <span class="t-white f16 pl-10 pr-10">联系我们</span> </router-link> |
        <el-link class="p-10"> <span class="t-white f16 pl-10 pr-10">意见反馈</span> </el-link>
      </div>
      <!-- <div class="text-center"><img src="../../assets/img/logo.jpg" /></div> -->
      <div class="text-center">网站技术支持：湖南金圣达空中医院信息服务有限公司</div>
      <div class="text-center">湘ICP备14007661号-4</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'MainFooter'
  }
</script>

<style scoped>
.footer{
  background: #215489;
  color: #fff;
}
</style>
