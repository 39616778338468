import Vue from 'vue'
import Router from 'vue-router'

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
}

Vue.use(Router)

const router = new Router({
    routes: [
        {
          path:'/',
          redirect:'/index'
        },
        {
          path: '/index',
          name: 'home',
          component: () => import('@/views/HomeView.vue')
        },
        {
            path: '/404',
            name: '404',
            component: () => import('@/views/404.vue')
        },
        {
            path: '/policy',
            name: 'Policy',
            component: () => import('@/views/policy/Policy.vue')
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/views/login/LoginView.vue')
        },
        {
            path: '/authentication',
            name: 'Authentication',
            component: () => import('@/views/Authentication.vue')
        },
        {
            path: '/register/pectoralgia',
            name: 'registerPectoralgia',
            component: () => import('@/views/register/RegisterPectoralgia.vue')
        },
        {
            path: '/register/unit',
            name: 'registerUnit',
            component: () => import('@/views/register/RegisterUnit.vue')
        },
        {
            path: '/register/doctor',
            name: 'registerDoctor',
            component: () => import('@/views/register/RegisterDoctor.vue')
        },
        //胸痛中心
        {
            path: '/auth',
            name: 'Auth',
            component: () => import('@/views/auth/Index'),
            children: [
                //机构信息
                {
                    path: '/auth/organ',
                    name: 'OrganInformation',
                    component: () => import('@/views/auth/OrganInformation/OrganInformation')
                },
                //编辑机构信息-胸痛中心p
                {
                    path: '/auth/organ/message/pectoralgia',
                    name: 'EditMessagePectoralgia',
                    component: () => import('@/views/auth/OrganInformation/EditMessagePectoralgia')
                },
                {
                    path: '/auth/organ/message/password',
                    name: 'ChangePassword',
                    component: () => import('@/views/auth/OrganInformation/ChangePassword')
                },


                //站内信
                {
                    path: '/auth/station/message',
                    name: 'StationMessage',
                    component: () => import('@/views/auth/StationMessage')
                },
                //发起认证
                {
                    path: '/auth/cert/index',
                    name: 'Index',
                    component: () => import('@/views/auth/Cert/index')
                },
                //发起认证-类型认证
                {
                    path: '/auth/cert/type',
                    name: 'TypeIndex',
                    component: () => import('@/views/auth/Cert/TypeIndex')
                },
                //发起认证-认证材料
                {
                    path: '/auth/cert/material',
                    name: 'MaterialIndex',
                    component: () => import('@/views/auth/Cert/MaterialIndex')
                },
                //数据填报平台
                {
                    path: '/auth/data',
                    name: 'DataPlatform',
                    component: () => import('@/views/auth/DataPlatform')
                },
                //建设材料
                {
                    path: '/auth/material/index',
                    name: 'Index',
                    component: () => import('@/views/auth/ConstructionMaterial/Index')
                },
                {
                    path: '/auth/material',
                    name: 'ConstructionMaterial',
                    component: () => import('@/views/auth/ConstructionMaterial/ConstructionMaterial')
                },
                //认证足迹
                {
                    path: '/auth/cert/footprint',
                    name: 'CertificationFootprint',
                    component: () => import('@/views/auth/CertificationFootprint')
                },
                //救治单元
                {
                    path: '/auth/treatment',
                    name: 'TreatmentUnit',
                    component: () => import('@/views/auth/TreatmentUnit')
                },
            ]
        },
        //救治单元
        {
            path: '/unit',
            name: 'Unit',
            component: () => import('@/views/unit/Index'),
            children: [
                // {
                // path: '/unit/materials',
                // name: 'AuthMaterials',
                // component: () => import('@/views/unit/Materials/AuthMaterials.vue')
                // },
                //机构信息
                {
                    path: '/unit/organ',
                    name: 'TreatOrganInformation',
                    component: () => import('@/views/unit/TreatOrganInformation')
                },
                //编辑机构信息-救治单元
                {
                    path: '/unit/organ/message/unit',
                    name: 'EditMessageUnit',
                    component: () => import('@/views/unit/EditMessageUnit')
                },
                //站内信
                {
                    path: '/unit/station/message',
                    name: 'StationMessage',
                    component: () => import('@/views/unit/StationMessage')
                },
                //建设材料
                // {
                //     path: '/unit/materials/index',
                //     name: 'Index',
                //     component: () => import('@/views/unit/Materials/Index')
                // },
                // {
                //     path: '/unit/materials',
                //     name: 'AuthMaterials',
                //     component: () => import('@/views/unit/Materials/AuthMaterials')
                // },
                //发起认证
                // {
                //     path: '/unit/cert/index',
                //     name: 'Index',
                //     component: () => import('@/views/unit/Cert/Index')
                // },
                // {
                //     path: '/unit/cert/type',
                //     name: 'TypeIndex',
                //     component: () => import('@/views/unit/Cert/TypeIndex')
                // },
                //数据填报平台
                {
                    path: '/unit/data',
                    name: 'DataPlatform',
                    component: () => import('@/views/unit/DataPlatform')
                },
            ]
        },

        {
          path: '/about/us',
          name: 'aboutUs',
          component: () => import('@/views/contactUs/AboutUs.vue')
        },
        /* 质控 */
        {
          path: '/quality/index',
          name: 'qualityIndex',
          component: () => import('@/views/qualityControl/QualityIndex.vue')
        },
        /* 认证指引 */
        {
          path: '/guide',
          name: 'guide',
          component: () => import('@/views/GuideView.vue'),
          children: [
            {
              path: '/certificate/authority',
              name: 'certificateAuthority',
              component: () => import('@/views/certificationGuidelines/CertificateAuthority.vue')
            },
            {
              path: '/certification/process',
              name: 'certificationProcess',
              component: () => import('@/views/certificationGuidelines/CertificationProcess.vue')
            },
            {
              path: '/certification/purpose',
              name: 'certificationPurpose',
              component: () => import('@/views/certificationGuidelines/CertificationPurpose.vue')
            },
            {
              path: '/certification/standards',
              name: 'certificationStandards',
              component: () => import('@/views/certificationGuidelines/CertificationStandards.vue')
            },
            {
              path: '/certification/standards/basic',
              name: 'certificationStandardsBasic',
              component: () => import('@/views/certificationGuidelines/CertificationStandardsBasic.vue')
            },
            {
              path: '/supervision/system',
              name: 'supervisionSystem',
              component: () => import('@/views/certificationGuidelines/SupervisionSystem.vue')
            }
          ]
        },
        /* 培训学习-视频 */
        {
            path: '/video/index',
            name: 'Index',
            component: () => import('@/views/video/index')
        },
        {
            path: '/video/detail',
            name: 'Detail',
            component: () => import('@/views/video/Detail')
        },
        {
            path: '/video/player',
            name: 'Detail',
            component: () => import('@/views/video/Player')
        },
    ]
})
// router.beforeEach((to, from, next) => {
//     const curruser = JSON.parse(localStorage.getItem("curruser"))
//         /* console.log(to, curruserInfo) */
//     if (!curruser && to.fullPath !== '/login') {
//         next({ path: '/login' });
//     } else {
//         next()
//     }
// })
export default router