
<template>
  <div class="header">
    <div class="wrapper top-lr-scatter">
      <div class="logo center-float-left" @click="$router.push('/')">
        <img src="../../assets/img/logo4.jpg" height="78" />
        <img src="../../assets/img/logo3.jpg" height="78" class="ml-10" />
        <div class="flex-1 t-white pl-10">
<!--          <div class="f16">湖南省卫生健康委员会</div>-->
          <div class="f28 t-cu">湖南胸痛中心</div>
          <div class="f14">Hunan Chest Pain Center</div>
        </div>
      </div>
      <div class="center-float-right pt-10 t-white">
        <div v-if="logined" class="center-float-left p-10 f16">欢迎{{user}}！ |</div>
        <div class="center-float-left p-10 f16"><router-link to="/index" class=" t-white">首页</router-link></div> |
        <div v-if="!logined" class="center-float-left p-10 mr-30 f16"><router-link to="/login" class=" t-white">去登录</router-link></div>
        <div v-else class="center-float-left p-10 mr-30 f16"><a @click="loginOut" class=" t-white">退出登录</a></div>
        <div>
          <el-input
            v-model="input3"
            placeholder="请输入关键检索词"
            class="input-with-select"
          >
            <template #append>
              <el-button color="#187bb2" icon="el-icon-search" />
            </template>
          </el-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'MainHeader',
    props:['loginActive'],
    data() {
      return {
        logined:false,
        input3: '',
        userInfo: null,
        user:'',
      }
    },
    mounted() {
      let userInfo = JSON.parse(localStorage.getItem('cpis-web_userInfo'))
      this.userInfo = userInfo && userInfo.id ? userInfo: null;
      this.logined = userInfo && userInfo.id
      this.user=userInfo && userInfo.id ?userInfo.name:'';
      console.log(this.userInfo);
    },
    methods: {
      loginOut() {
        console.log('退出登录！！！')
        localStorage.removeItem('cpis-web_userInfo')
        localStorage.removeItem('cpis-web_token')
        this.$message.success('退出登录成功！')
        this.userInfo = null;
        this.logined = false;
      }
    }
  }
</script>
<style lang="scss">
.header{
  background-color: #008df1;
  background: url('../../assets/img/header-bg.jpg') no-repeat center top;
  .logo{
    padding: 20px 0;
    position: relative;
    letter-spacing: 2px;
    cursor: pointer;
    img{
      border-radius: 50%;
      background: #fff;
      padding: 1px;
    }
  }
  .el-input-group{
    box-shadow: 0 0 0 4px rgba(255,255,255, 0.3);
    border-radius:  12px;
  }
  .el-input-group__append{
    background-color: #187bb2!important;
  }
  .el-input-group--append>.el-input__wrapper {
    border-radius: 12px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .el-input-group__append{
    border-radius: 12px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: #FB9337;
    box-shadow: none;
    color: #fff;
  }
}
</style>
