<template>
  <el-container>
    <el-header> <MainHeader /> </el-header>
    <el-main >
      <div class="wrapper">
        <router-view></router-view>
      </div>

    </el-main>
    <el-footer> <MainFooter /> </el-footer>
  </el-container>
</template>

<script>
import MainHeader from './components/layout/MainHeader.vue'
import MainFooter from './components/layout/MainFooter.vue'
export default {
  name: 'App',
  components: {MainHeader, MainFooter},
  data(){
    return {
      message:[],
    }
  },
  mounted() {

  },
  methods:{

  }
}
</script>

<style scoped>
.el-header, .el-footer{
  height: auto !important;
  padding: 0;
}
.el-main{min-height: calc(100vh - 268px);}


</style>
