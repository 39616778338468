/**
 * 分页条默认设置
 */
const pagination = {
    current: 1,
    total: 0,
    pageSizeOptions: [9, 18, 27, 36],
    pageSize: 9
};

const basicUrl = process.env.VUE_APP_BASIC_ApiUrl;
const skipPageUrl = process.env.VUE_APP_Formal_ApiUrl;

export default {
    pagination,
    basicUrl,
    skipPageUrl
}